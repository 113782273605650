<template>
  <div class="common-layout">
    <el-container id = "main" style="height: 100%">
      <el-header>Header</el-header>
      <el-container class="all">
        <el-aside width="300px" class="tree-container">
            <el-tree class="custom-tree" :data="tree" node-key="id" @node-click="handleNodeClick" ></el-tree>
        </el-aside>
        <div class="resize" >⋮</div>
        <el-main style="padding: 0px;" class="right">
          <div align="left" style="position: relative" v-if="markdownPath">
           <div class="view-div1"><el-button class="edit-btn" @click="handleClick">编辑</el-button></div>
            <div class="view-div2"><MdPreview v-model="markdown"></MdPreview></div>
          </div>
        </el-main>
      </el-container>
      <el-footer>Footer</el-footer>
    </el-container>
  </div>

</template>

<script>
import router from '@/router';
import 'md-editor-v3/lib/style.css';
import axios from '@/api/axios'; // 引入封装好的api
import { MdPreview } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';

// 左右拖动事件
function dragControllerLR() {
  let resize = document.getElementsByClassName("resize");
  let left = document.getElementsByClassName("el-aside");
  let right = document.getElementsByClassName("right");
  let box = document.getElementsByClassName("all");
  for (let i = 0; i < resize.length; i++) {
    // 鼠标按下事件
    resize[i].onmousedown = function (e) {
      let startX = e.clientX;
      resize[i].left = resize[i].offsetLeft;
      // 鼠标拖动事件
      document.onmousemove = function (e) {
        let endX = e.clientX;
        let moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
        let maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

        // if (moveLen < 300) moveLen = 300; // 左边区域的最小宽度为50px
        if (moveLen > maxT - 300) moveLen = maxT - 300; //右边区域最小宽度为150px

        resize[i].style.left = moveLen; // 设置左侧区域的宽度

        for (let j = 0; j < left.length; j++) {
          left[j].style.width = moveLen + "px";
          right[j].style.width = box[i].clientWidth - moveLen - 10 + "px";
        }
      };
      // 鼠标松开事件
      document.onmouseup = function (e) {;
        document.onmousemove = null;
        document.onmouseup = null;
      };
      return false;
    };
  }
}


export default {
  components: {
    MdPreview
  },

  async mounted() {
    dragControllerLR()
    const navData = await axios.post("/md/query/nav")
    this.tree = navData.data;

    if (this.markdownPath) {
      axios.post("/md/query/markdown",{
        "path": this.markdownPath
      }).then(response => {
        this.markdown = response.data.data;
      }).catch(error => {
        console.error('请求失败:', error);
        this.markdown = ''
      });
    }
  },

  data() {
    return {
      tree:[],
      markdown: '',
      clickNode:null,
    };
  },
  methods: {
    handleNodeClick(data) {
      this.clickNode = data;
      if (data.path) {
        axios.post("/md/query/markdown",{
          "path": "/"+data.path
        }).then(response => {
          this.markdown = response.data.data;
        }).catch(error => {
          console.error('请求失败:', error);
          this.markdown = ''
        });
      }
      
      if (data.path){
        router.push('/views/'+data.path);
      }
    },

    // 定义点击事件的处理函数
    handleClick() {
      router.push({
        path:'/edit/'+this.$route.params.path,
        query:{
          id:this.clickNode.id,
        }
      });
    },

  },
  computed:{
    markdownPath:{
      get(){
        return this.$route.params.path== undefined ? '':`/${this.$route.params.path}`;
      }
    },
  }
};



</script>

<style scoped>
.common-layout{
  height: 100%;
  bottom: 0;
}

.main{
  height: 100%;
  bottom: 0;
  text-align: left;
}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-footer{
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  bottom: 0;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.tree-container {
  height: 100%;
  border-right: 1px solid #dcdfe6; /* 根据需要设置边框颜色 */
  overflow-y: auto;
  overflow-x: auto;
  background-color: #ffffff;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 30px;
}

.el-aside::-webkit-scrollbar {
  display: none;
}

.view-div1 {
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #dcdfe6; /* 根据需要设置边框颜色 */

}

.view-div2{
  position: absolute;
  top: 50px;
  width: 100%;
}

.edit-btn{
  position: absolute;
  right: 20px;
  top:20px;
}

.md-editor-preview figure{
  margin: 0px;
}

.default-theme img{
  border: 0px !important;
  border-radius: 0px !important;
}

.md-editor-preview h1{
  border-bottom: 1px solid #eaecef;
  padding-bottom: .3rem;
  margin: .83em 0;
}


.md-editor-preview h2{
  border-bottom: 1px solid #eaecef;
  padding-bottom: .3rem;
  margin: .83em 0;
}

</style>

